











































import './styles/CompositeActionsPopup.scss';
import './styles/CompositeActionsPopupAdaptive.scss';
import './styles/DeleteWorkPopup.scss';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { IWork } from '@/store/modules/work-list/interfaces/Interfaces';
import { IWorkListPopupsState } from '@/store/modules/work-list/modules/popups/Interfaces';
import { WorkListActions } from '@/store/modules/work-list/Types';
import { PopupActions } from '@/store/Popup';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');
const NSWorkList = namespace('storeWorkList');

@Component({
    name: 'DeleteWorkPopup',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseComposite: () => import('@components/BaseComposite'),
    },
})
export default class DeleteWorkPopup extends Vue {
    $refs!: {
        basePopup: any;
    };

    @NSWorkList.Getter('workList') workList!: IWork[];
    @NSWorkListPopups.Getter('deleteWorkPopupToggler') deleteWorkPopupToggler!: boolean;
    @NSWorkList.Action(WorkListActions.A_DELETE_WORK) deleteWork!: (work: IWork) => Promise<void>;
    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    @Watch('deleteWorkPopupToggler')
    watchDeleteWorkPopupToggler() {
        if (this.deleteWorkPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    workToDelete = null as IWork | null;

    get workToDeleteIsEmpty(): boolean {
        return !this.workToDelete?.constructs?.length && !this.workToDelete?.children?.length;
    }

    closePopup() {
        this.resetWorkToDelete();
        this.togglePopup({
            popupToggler: 'deleteWorkPopupToggler',
            state: false,
        });
    }

    changeWorkToDelete(work: IWork): void {
        const workIsSelected = this.workToDelete === work;

        if (workIsSelected) this.resetWorkToDelete();
        else this.selectWorkToDelete(work);
    }

    selectWorkToDelete(work: IWork): void {
        this.workToDelete = work;
    }

    resetWorkToDelete(): void {
        this.workToDelete = null;
    }

    dispatchDeleteAction(): void {
        if (this.workToDelete) this.deleteWork(this.workToDelete).then(() => {
            this.closePopup();
            this.$emit('success-delete-work');
        });
    }
}
